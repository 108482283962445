<script setup>
import Section from '@/Components/Section.vue';
import Heading from '@/Components/Heading.vue';
import Progress from '@/Components/Progress.vue';
import Satisfaction from '@/Pages/Dashboard/Partials/Revenue/Satisfaction.vue';
import { formatPrice, formatPriceToK } from '@/Components/Composable/Monetize.js';
import config from '/tailwind.config.js';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { onBeforeUnmount, onMounted, ref } from 'vue';
import Loader from '@/Components/Loader.vue';

ChartJS.register( Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale )

const props = defineProps( {
    profile: Object,
    interval: Number,
} )

// Set projects list
const pipeline = ref( null );
const scores = ref( null );
const revenue = ref( null );

// Load revenue when mounting and subscribe to websocket
onMounted( () => {
    loadRevenue();

    // Interval for every 5 minutes
    setInterval(() => {
        loadRevenue();
    }, props.interval);

    window.Echo.channel( `dashboard.${props.profile.slug}` ).listen( '.revenue', (data) => {
        if (data?.hasChanged) {
            loadRevenue();
        }
    } )
} )

// Unsubscribe from webhook when unmounting
onBeforeUnmount( () => {
    window.Echo.channel( `dashboard.${props.profile.slug}` ).stopListening( '.revenue' );
} )

/**
 * Load revenue from API
 */
const loadRevenue = () => {
    axios.get( `/api/${props.profile.slug}/revenue` ).then( (response) => {
        const { data } = response;

        pipeline.value = data.pipeline;
        scores.value = data.scores;
        revenue.value = data.revenue;
    } );
}

const chartOptions = {
    scales: {
        x: {
            display: false,
        },
        y: {
            beginAtZero: true,
            ticks: {
                callback: function (value) {
                    return formatPriceToK( value );
                }
            },
        }
    },
    plugins: {
        legend: {
            display: false,
        },
    },
    responsive: true,
    // maintainAspectRatio: false,
    aspectRatio: .9,
}

/**
 * Pipeline chart data
 *
 * @returns {{datasets: [{backgroundColor: (function(*): CanvasGradient), barPercentage: number, data: *[], borderRadius: {topLeft: number, topRight: number}, categoryPercentage: number, label: string},{backgroundColor: string[], barPercentage: number, data: *[], borderRadius: {topLeft: number, topRight: number}, categoryPercentage: number, label: string}], labels: string[]}}
 */
const pipelineChartData = () => {
    const style = getComputedStyle(document.body);
    const color500 = style.getPropertyValue('--color-primary-500');
    const color400 = style.getPropertyValue('--color-primary-400');
    const color200 = style.getPropertyValue('--color-primary-200');

    return {
        labels: [ 'Pipeline' ],
        datasets: [
            {
                label: 'Potentiële omzet',
                data: [ pipeline.value.potential ],
                backgroundColor: (ctx) => {
                    const canvas = ctx.chart.ctx;
                    const gradient = canvas.createLinearGradient(0,0,0,160);

                    gradient.addColorStop(0, color500);
                    gradient.addColorStop(1, color400);

                    return gradient;
                },
                barPercentage: .5,
                categoryPercentage: 1,
                borderRadius: {
                    'topLeft': 4,
                    'topRight': 4,
                },
            },
            {
                label: 'Omzet x kans',
                backgroundColor: [ color200 ],
                data: [ pipeline.value.chance ],
                barPercentage: .5,
                categoryPercentage: 1,
                borderRadius: {
                    'topLeft': 4,
                    'topRight': 4,
                },
            },
        ]
    };
};
</script>

<template>
    <Section>
        <div class="grid grid-cols-5 gap-4 w-full h-full">
            <div class="flex flex-col col-span-2">
                <article v-if="pipeline">
                    <Heading class="mb-5" title="Pipeline" :subtitle="`${pipeline.count} projecten`"/>

                    <div class="grid grid-cols-2 gap-4 items-center">
                        <div class="col-span-1">
                            <Bar id="pipeline" :options="chartOptions" :data="pipelineChartData()"/>
                        </div>

                        <div class="col-span-1">
                            <ul class="grid grid-cols-1 gap-2 text-xs text-gray-400">
                                <li class="flex">
                                    <span class="flex w-4 h-4 mr-2 rounded bg-gradient-to-r from-primary-400 to-primary-500"/>
                                    <span>Potentiële omzet</span>
                                </li>
                                <li class="flex">
                                    <span class="flex w-4 h-4 mr-2 rounded bg-primary-200"/>
                                    <span>Omzet x kans</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
                <Loader v-else/>

                <article class="my-auto" v-if="scores">
                    <Heading title="Tevredenheid" class="mb-2"/>
                    <div class="grid grid-cols-2 gap-4">
                        <Satisfaction title="Medewerkers" :score="scores.employees"/>
                        <Satisfaction title="Klanten" :score="scores.customers"/>
                    </div>
                </article>
                <Loader v-else/>
            </div>
            <div class="col-span-3">
                <div class="grid grid-cols-1 gap-6 w-full h-full" v-if="revenue">
                    <div class="col-span-1"  v-if="revenue.hasOwnProperty('year')">
                        <Heading :title="revenue.year.description" :subtitle="formatPrice(revenue.year.goal, true)" :large="false"/>
                        <Progress :current="revenue.year.current" :goal="parseInt(revenue.year.goal)" type="money"/>
                    </div>

                    <div class="col-span-1" v-if="revenue.hasOwnProperty('quarter')">
                        <Heading :title="revenue.quarter.description" :subtitle="formatPrice(revenue.quarter.goal, true)" :large="false"/>
                        <Progress :current="revenue.quarter.current" :goal="revenue.quarter.goal" type="money"/>
                    </div>

                    <div class="col-span-1" v-if="revenue.hasOwnProperty('optimization')">
                        <Heading :title="revenue.optimization.description" :subtitle="revenue.optimization.goal" :large="false" />
                        <Progress :current="revenue.optimization.current" :goal="revenue.optimization.goal" type="number"/>
                    </div>
                </div>
                <Loader v-else/>
            </div>
        </div>
    </Section>
</template>
